import React from 'react'
import { Box, Grid } from '@chatterbug/aaron'

import PriceAndIcon from './subcomponents/PriceAndIcon/PriceAndIcon'
import { useTheme } from 'src/lib/theme'
import { Currency, Product } from 'src/types'

export type Props = {
  variant: Product
  label: string
  name: string
  price: number
  priceNote?: string
  icon: string
  subtitle: string
  hideSubtitle?: boolean
  descriptionSection?: React.ReactNode
  buttonSection?: React.ReactNode
  popularNote?: string
  currency?: Currency
}

const ProductCardSkeleton: React.FC<Props> = ({
  label,
  name,
  price,
  priceNote,
  icon,
  subtitle,
  hideSubtitle,
  descriptionSection,
  buttonSection,
  popularNote,
  variant,
  currency = Currency.eur,
}) => {
  const theme = useTheme()

  return (
    <Box
      width={{
        tablet: variant === 'streams' ? 230 : 260,
        tabletWide: 260,
      }}
      borderRadius="16px"
      bg="white100"
      boxShadow="overlay"
      data-testid="product-card"
      overflow="hidden"
      className={`cb-product-card-${name}`}
    >
      {popularNote && (
        <Box
          variant="text.paragraph"
          px="2x"
          py="1x"
          bg="yellow50"
          textAlign="center"
          as="p"
        >
          {popularNote}
        </Box>
      )}
      <Box p="3x">
        <Grid
          gridGap={{
            _: '0 8px',
            tablet: '2x',
          }}
          gridTemplateColumns={{
            _: '1fr auto',
            tablet: '1fr',
          }}
          sx={{
            gridTemplateAreas: `
              "label       price"
              "description price"
            `,
            [`@media screen and (min-width: ${theme?.mediaQueries.tablet})`]: {
              gridTemplateAreas: `
                "label"
                "price"
                "description"
              `,
            },
          }}
        >
          {/* label */}
          <Box
            variant="text.h3"
            mb={{
              _: '1x',
              tablet: 0,
            }}
            sx={{ gridArea: 'label' }}
          >
            {label}
          </Box>
          {/* icon and price */}
          <Box
            sx={{
              gridArea: 'price',
              minWidth: 140,
              borderLeftWidth: '1px',
              borderLeftStyle: 'solid',
              borderLeftColor: 'gray80',
              paddingLeft: '10px',
              [`@media screen and (min-width: ${theme?.mediaQueries.tablet})`]: {
                borderLeft: 'none',
                paddingLeft: 0,
                borderBottomWidth: 1,
                borderBottomStyle: 'solid',
                borderBottomColor: hideSubtitle ? 'transparent' : 'gray80',
                paddingBottom: '1x',
              },
            }}
          >
            <PriceAndIcon
              icon={icon}
              price={price}
              priceNote={priceNote}
              variant={variant}
              currency={currency}
            />
          </Box>
          {/* Description */}
          {(descriptionSection || subtitle) && (
            <Box
              sx={{
                gridArea: 'description',
                visibility: hideSubtitle ? 'hidden' : 'inherit',
              }}
            >
              <Box
                as="p"
                variant="text.h4"
                lineHeight={1.6}
                data-testid="subtitle"
              >
                {subtitle}
              </Box>
              {descriptionSection}
            </Box>
          )}
        </Grid>
        {/* button */}
        {buttonSection && <Box mt="2x">{buttonSection}</Box>}
      </Box>
    </Box>
  )
}

export default ProductCardSkeleton
