import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Box } from '@chatterbug/aaron'

import { TABLE_CELL_WIDTH } from '../../utils'

export type Props = {
  title?: string
  subtitle?: string
  fontWeight?: string
  hideOnMobile?: boolean
}

const HeaderCell: React.FC<Props> = ({
  title,
  subtitle,
  fontWeight,
  hideOnMobile,
}) => {
  const t = useTranslate()

  return (
    <Box
      as={hideOnMobile ? 'div' : 'th'}
      verticalAlign="top"
      width={TABLE_CELL_WIDTH}
      display={
        hideOnMobile ? { _: 'none', tablet: 'inline-block' } : 'inline-block'
      }
      height="100px"
    >
      {title ? (
        <Box
          variant="text.paragraph"
          textAlign="center"
          color="black0"
          fontSize={{ _: 'xxs', tablet: 'sm' }}
          fontWeight={fontWeight}
        >
          {title}
        </Box>
      ) : (
        <Box display="none">{t('Compare Chatterbug')}</Box>
      )}
      <Box
        variant="text.subtitle"
        textAlign="center"
        color="grey46"
        display={{ _: 'none', laptop: 'block' }}
      >
        {subtitle}
      </Box>
    </Box>
  )
}

export default HeaderCell
