import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Box } from '@chatterbug/aaron'
import { CompareTableValue } from 'src/types'

import HeaderRow from '../HeaderRow/HeaderRow'
import TableRow from '../TableRow/TableRow'

const ComparisonTable: React.FC = () => {
  const t = useTranslate()
  return (
    <Box
      as="table"
      width="auto"
      m="0 auto"
      aria-label="Compare Chatterbug Table"
      // needed for the border to be seamless
      sx={{
        borderCollapse: 'collapse',
      }}
    >
      <thead>
        {/* header row */}
        <HeaderRow />
      </thead>
      <tbody>
        {/* content rows */}
        <TableRow
          text={t('Online Study Activities')}
          checkmarks={[
            CompareTableValue.lightCheck,
            CompareTableValue.cross,
            CompareTableValue.cross,
            CompareTableValue.check,
          ]}
        />
        <TableRow
          text={t('Adaptive Course')}
          checkmarks={[
            CompareTableValue.lightCheck,
            CompareTableValue.cross,
            CompareTableValue.cross,
            CompareTableValue.check,
          ]}
        />
        <TableRow
          text={t('Easy Scheduling')}
          checkmarks={[
            CompareTableValue.lightCheck,
            CompareTableValue.cross,
            CompareTableValue.cross,
            CompareTableValue.check,
          ]}
        />
        <TableRow
          text={t('Integrated Curriculum')}
          checkmarks={[
            CompareTableValue.lightCheck,
            CompareTableValue.cross,
            CompareTableValue.lightCheck,
            CompareTableValue.check,
          ]}
        />
        <TableRow
          text={t('Native Speaking Practice')}
          checkmarks={[
            CompareTableValue.cross,
            CompareTableValue.lightCheck,
            CompareTableValue.lightCheck,
            CompareTableValue.check,
          ]}
        />
        <TableRow
          text={t('Private 1:1 Lessons')}
          checkmarks={[
            CompareTableValue.cross,
            CompareTableValue.lightCheck,
            CompareTableValue.cross,
            CompareTableValue.check,
          ]}
        />
      </tbody>
    </Box>
  )
}

export default ComparisonTable
