import React from 'react'
import { useTranslate } from 'react-polyglot'
import { PageProps } from 'gatsby'
import { PageContext } from 'src/types'
import { Box } from '@chatterbug/aaron'
import Layout from 'src/components/Layout/Layout'
import Head from 'src/components/Head/Head'
import PageContainer from 'src/components/PageContainer/PageContainer'
import PricingPlans from 'src/sections/PricingPlans/PricingPlans'
import CompareChatterbug from 'src/sections/CompareChatterbug/CompareChatterbug'
import LessonsHeader from 'src/components/LessonsHeader/LessonsHeader'
import { LessonsThemeProvider } from 'src/lib/pageThemeContext'

const PricingPage: React.FC<PageProps<unknown, PageContext>> = ({
  pageContext,
}) => {
  const t = useTranslate()

  return (
    <LessonsThemeProvider>
      <Layout pageContext={pageContext} hideSiteHeader>
        <Head title={t('Pricing')} pageContext={pageContext} />
        <PageContainer>
          <LessonsHeader canonicalPath={pageContext.canonicalPath} />
          <Box mt={56} mb={88}>
            <PricingPlans />
          </Box>
        </PageContainer>
        <CompareChatterbug />
      </Layout>
    </LessonsThemeProvider>
  )
}

export default PricingPage
