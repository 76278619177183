import React from 'react'
import { Grid } from '@chatterbug/aaron'
import { Currency } from 'src/types'

export type Props<T> = {
  items: T[]
  ItemComponent: React.FC<
    T & { currency?: Currency; showButton?: boolean; onError?: () => void }
  >
  currency?: Currency
  showButton?: boolean
  onError?: () => void
}

// eslint-disable-next-line @typescript-eslint/ban-types
const ProductList = <T extends {}>({
  items,
  ItemComponent,
  currency,
  showButton,
  onError,
}: Props<T>) => {
  const tabletColumns = items.length % 2 ? 3 : 2

  return (
    <Grid
      alignItems="flex-end"
      justifyContent={{
        _: 'flex-start',
        tablet: 'center',
      }}
      gridGap="2x"
      columns={{
        _: '1fr',
        tablet: `repeat(${tabletColumns}, auto)`,
        laptop: `repeat(${items.length}, auto)`,
      }}
    >
      {items.map((plan, i) => {
        return (
          <ItemComponent
            key={`product-card-${i}`}
            {...plan}
            currency={currency}
            showButton={showButton}
            onError={onError}
          />
        )
      })}
    </Grid>
  )
}

export default ProductList
