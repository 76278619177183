import React from 'react'
import { useTranslate } from 'react-polyglot'
import Button, { BUTTON_VARIANTS } from 'src/components/Button/Button'
import { getLessonsSignupUrl } from 'src/lib/app'
import { useLocale } from 'src/lib/I18nContext'
import { LessonProductItem, Product } from 'src/types'

import DescriptionLine from './subcomponents/DescriptionLine/DescriptionLine'
import ProductCardSkeleton from './ProductCardSkeleton'

export type Props = LessonProductItem

const ProductCardForLessonPricingPlan: React.FC<Props> = ({
  label,
  name,
  numberOfLessons,
  pricePerExtraLesson,
  icon,
  price,
  isPopular,
}) => {
  const t = useTranslate()
  const locale = useLocale()

  return (
    <ProductCardSkeleton
      variant={Product.lessons}
      label={label}
      name={name}
      icon={icon}
      price={price}
      priceNote={t('product_card_plan.price_note')}
      subtitle={t(
        '%{smart_count} Live Lesson |||| %{smart_count} Live Lessons',
        {
          smart_count: numberOfLessons,
        }
      )}
      descriptionSection={
        <DescriptionLine
          text={t('product_card_plan.description', {
            pricePerExtraLesson,
          })}
        />
      }
      buttonSection={
        <Button
          href={getLessonsSignupUrl(locale)}
          label={t('product_card_plan.cta')}
          variant={BUTTON_VARIANTS.primary}
          width="100%"
          className={`cta-button-select-plan-${name}`}
          data-l1={locale}
        />
      }
      popularNote={isPopular && t('product_card_plan.popular_note')}
    />
  )
}

export default ProductCardForLessonPricingPlan
