import React from 'react'
import { Box, Flex, Grid } from '@chatterbug/aaron'

const space = 'auto'
const logoWidth = '132px'
const separatorWidth = '1px'

export type Props = {
  Logo: React.ReactNode
  paragraph: string
  cta: {
    text: string
    link: string
  }
}

const ProductBanner = ({ Logo, paragraph, cta }: Props) => {
  return (
    <Grid
      borderRadius="16px"
      bg="white100"
      boxShadow="overlay"
      data-testid="streams-banner"
      gridTemplateColumns={{
        _: 'auto',
        tablet: `${space} ${logoWidth} ${space} ${separatorWidth} ${space} auto ${space}`,
      }}
      gridTemplateRows={{
        _: `${space} auto ${space} ${separatorWidth} ${space} auto ${space}`,
        tablet: 'auto',
      }}
      py="2x"
      px="1x"
      gridGap="2x"
      minHeight="88px"
      maxWidth="0" // Hack for Firefox (this will fallback on the `minWidth`)
      minWidth="100%"
    >
      <Space />

      {/* logo */}
      <Flex alignItems="center" justifyContent="center">
        {Logo}
      </Flex>

      <Space />

      {/* separator */}
      <Box backgroundColor="gray80" />

      <Space />

      {/* paragraph */}
      <Flex alignItems="center">
        <Box
          sx={{ wordBreak: 'break-word' }}
          px={{
            _: '2x',
            tablet: 'inherit',
          }}
        >
          <Box
            variant="text.paragraph"
            as="span"
            dangerouslySetInnerHTML={{
              __html: paragraph,
            }}
          />
          &nbsp;
          <Box
            variant="text.paragraph"
            fontWeight="semiBold"
            as="a"
            href={cta.link}
            color="blue42"
            sx={{
              ':hover': {
                color: 'blue30',
              },
            }}
          >
            {cta.text}
          </Box>
        </Box>
      </Flex>
      <Space />
    </Grid>
  )
}

const Space = () => <div />

export default ProductBanner
