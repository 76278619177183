import React from 'react'
import { Box, Flex } from '@chatterbug/aaron'
import FullWidthBackground from 'src/components/FullWidthBackground/FullWidthBackground'
import SectionSeparator from 'src/sections/SectionSeparator/SectionSeparator'
import { NavigationItemName } from 'src/types'

import background from './assets/background.svg'
import ComparisonTable from './subcomponents/ComparisonTable/ComparisonTable'

const CompareChatterbug: React.FC = () => {
  return (
    <FullWidthBackground image={background} width={1400} height={932}>
      <Flex flexDirection="column">
        <Box mt="4x" mb="80px">
          <SectionSeparator name={NavigationItemName.compareChatterbug} />
        </Box>
        <Box mb="144px">
          <ComparisonTable />
        </Box>
      </Flex>
    </FullWidthBackground>
  )
}

export default CompareChatterbug
