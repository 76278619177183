import React from 'react'
import { Box } from '@chatterbug/aaron'
import { useTranslate } from 'react-polyglot'

import HeaderCell from '../HeaderCell/HeaderCell'

const HeaderRow: React.FC = () => {
  const t = useTranslate()
  return (
    <Box as="tr">
      <HeaderCell />
      <HeaderCell title={t('Digital Apps')} subtitle="Duolingo, Babbel" />
      <HeaderCell
        title={t('Tutor Marketplaces')}
        subtitle="iTalki, LiveLingua"
      />
      <Box as="td" display="inline-block">
        <HeaderCell
          hideOnMobile
          title={t('In-person schools')}
          subtitle="Goethe-Institut, Alliance Française"
        />
      </Box>

      <HeaderCell title="Chatterbug" fontWeight="semiBold" />
    </Box>
  )
}

export default HeaderRow
