import React from 'react'
import { Box } from '@chatterbug/aaron'

import ContentCell from '../ContentCell/ContentCell'
import { TABLE_CELL_WIDTH } from '../../utils'
import { CompareTableValue } from 'src/types'

export type Props = {
  text: string
  checkmarks: [
    CompareTableValue,
    CompareTableValue,
    CompareTableValue,
    CompareTableValue
  ]
}

const TableRow: React.FC<Props> = ({ text, checkmarks }) => {
  return (
    <Box
      as="tr"
      data-testid="table-content-row"
      sx={{
        'td:last-child': {
          borderRight: 0,
        },
      }}
    >
      <Box
        as="td"
        variant="text.paragraph"
        width={TABLE_CELL_WIDTH}
        display="inline-block"
        color="black0"
        height="70px"
        fontSize={{ _: 'xxs', tablet: 'sm' }}
      >
        {text}
      </Box>
      <ContentCell checkmark={checkmarks[0]} />
      <ContentCell checkmark={checkmarks[1]} />
      <ContentCell checkmark={checkmarks[2]} hideOnMobile />
      <ContentCell checkmark={checkmarks[3]} />
    </Box>
  )
}

export default TableRow
