import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Box, Flex } from '@chatterbug/aaron'

const PricingPlanFooter: React.FC = () => {
  const t = useTranslate()

  const showChat = () => window.Intercom('show')

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
    >
      <Box
        variant="text.paragraph"
        textAlign="center"
        dangerouslySetInnerHTML={{
          __html: t(
            'Want your <strong>employer</strong> or <strong>school</strong> to pay for your learning on Chatterbug?'
          ),
        }}
      />

      <Flex gridGap=".3rem">
        <Box
          as="button"
          onClick={showChat}
          variant="text.paragraph"
          fontWeight="semiBold"
          color="blue42"
          sx={{
            background: "none",
            border: "none",
            cursor: "pointer",

            ':hover': {
              color: 'blue30',
            },
          }}
        >
          {t('Request a personalized course package for upfront payment')}
        </Box>
      </Flex>
    </Flex>
  )
}

export default PricingPlanFooter
