import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Flex, Box, Image } from '@chatterbug/aaron'
import {
  BusinessNavigationItemName,
  GiftsNavigationItemName,
  LessonsNavigationItemName,
  NavigationItemName,
  OrgItemName,
  StreamsNavigationItemName,
} from 'src/types'

import star from './assets/star.svg'
import gift from './assets/gift.svg'

// TODO: refactor to pass a label as props
export type Props = {
  name:
    | NavigationItemName
    | GiftsNavigationItemName
    | StreamsNavigationItemName
    | LessonsNavigationItemName
    | BusinessNavigationItemName
    | OrgItemName
  color?: string
}

const SectionSeparator: React.FC<Props> = ({ name, color }) => {
  const t = useTranslate()

  const SECTIONS = {
    [String(OrgItemName.products)]: {
      label: t('Our Products'),
    },
    [String(OrgItemName.moreProducts)]: {
      label: t('More from Chatterbug'),
    },
    [String(OrgItemName.news)]: {
      label: t('News from Chatterbug'),
    },
    [String(NavigationItemName.whyChatterbug)]: {
      label: t('Why Chatterbug?'),
      image: star,
    },
    [String(LessonsNavigationItemName.lessonsFeatures)]: {
      label: t('Features'),
    },
    [String(LessonsNavigationItemName.method)]: {
      label: t('A comprehensive language tool'),
    },
    [String(LessonsNavigationItemName.inAction)]: {
      label: t('See lessons in action'),
    },
    [String(LessonsNavigationItemName.students)]: {
      label: t('What our students say about Lessons'),
    },
    [String(LessonsNavigationItemName.tutors)]: {
      label: t('Get to know some of our tutors'),
    },
    [String(BusinessNavigationItemName.howItWorks)]: {
      label: t('How Chatterbug works for learners'),
    },
    [String(NavigationItemName.compareChatterbug)]: {
      label: t('Compare Chatterbug'),
    },
    [String(GiftsNavigationItemName.vouchers)]: {
      label: t('Choose a gift to give'),
      image: gift,
    },
    [String(StreamsNavigationItemName.features)]: {
      label: t('Features'),
    },
    [String(StreamsNavigationItemName.streamers)]: {
      label: t('Our streamers'),
    },
    [String(StreamsNavigationItemName.video)]: {
      label: t('Learn more about Streams'),
    },
  }

  const section = SECTIONS[name]

  if (!section) {
    return null
  }

  return (
    <Flex flexDirection="column" alignItems="center" pt="3x" px="3x">
      {section.image && (
        <Image
          src={section.image}
          alt=""
          width={48}
          height={48}
          loading="lazy"
          data-testid="separator-image"
        />
      )}
      <Box
        as="h2"
        variant="text.h3"
        mt="1x"
        sx={{
          fontSize: '28px',
          color,
          textAlign: 'center',
        }}
      >
        {section.label}
      </Box>
    </Flex>
  )
}

export default SectionSeparator
