import React from 'react'
import { Box, Flex, Image } from '@chatterbug/aaron'
import colors from '@chatterbug/theme/tokens/colors'
import { Currency } from 'src/types'

export type Props = {
  variant: 'lessons' | 'streams'
  icon: string
  price: number
  priceNote?: string
  currency?: Currency
}

const COLORS: Record<Props['variant'], keyof typeof colors> = {
  lessons: 'pink51',
  streams: 'blue42',
}

const PriceAndIcon: React.FC<Props> = ({
  icon,
  price,
  priceNote,
  variant,
  currency,
}) => {
  return (
    <Box position="relative" mb="2x">
      <Flex flexDirection="column">
        <Flex fontFamily="body">
          {/* dollars goes in front of the price */}
          <Box
            as="p"
            color={COLORS[variant]}
            fontWeight="semiBold"
            display={currency === 'usd' ? 'block' : 'none'}
          >
            {'$'}
          </Box>
          <Box
            as="p"
            color={COLORS[variant]}
            fontSize="48px"
            lineHeight="48px"
            fontWeight="semiBold"
            data-testid="price"
          >
            {price}
          </Box>
          <Box
            as="p"
            color={COLORS[variant]}
            fontWeight="semiBold"
            display={currency === 'eur' ? 'block' : 'none'}
          >
            {'€'}
          </Box>
        </Flex>
        {priceNote && (
          <Box as="small" variant="text.subtitle" fontSize="xxs" color="gray45">
            {priceNote}
          </Box>
        )}
      </Flex>
      <Image
        src={icon}
        height={88}
        sx={{
          position: 'absolute',
          top: -8,
          right: 0,
        }}
        alt=""
        loading="lazy"
        display={{
          _: 'none',
          tablet: 'block',
        }}
      />
    </Box>
  )
}

export default PriceAndIcon
