import React from 'react'
import { Box, ExtendedBoxProps } from '@chatterbug/aaron'

export type Props = {
  text: string
  minHeight?: ExtendedBoxProps['minHeight']
}

const DescriptionLine: React.FC<Props> = ({ text, minHeight }) => {
  return (
    <Box
      variant="text.paragraph"
      lineHeight={1.6}
      dangerouslySetInnerHTML={{
        __html: text,
      }}
      minHeight={minHeight}
    />
  )
}

export default DescriptionLine
