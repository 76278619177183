import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Box, Flex } from '@chatterbug/aaron'

const PricingPlanHeading: React.FC = () => {
  const t = useTranslate()

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      data-testid="price-plans-heading"
    >
      <Box
        as="h1"
        variant="text.paragraph"
        fontSize="lg"
        mb="3x"
        color="black0"
        textAlign="center"
        dangerouslySetInnerHTML={{
          __html: t(
            'Choose a <strong>plan</strong> that works for <strong>you</strong>!'
          ),
        }}
      />
      <Box
        as="h2"
        variant="text.paragraph"
        textAlign="center"
        dangerouslySetInnerHTML={{
          __html: t(
            '14 day free trial. Cancel anytime. All self study activities included across all plans.'
          ),
        }}
      />
    </Flex>
  )
}

export default PricingPlanHeading
