import React from 'react'
import { useTranslate } from 'react-polyglot'
import StreamsLogo from 'src/components/StreamsLogo/StreamsLogo'
import { getLocalizedPath } from 'src/config/i18n'
import { useLocale } from 'src/lib/I18nContext'
import ProductBanner from 'src/components/ProductBanner/ProductBanner'

const StreamsBanner = () => {
  const t = useTranslate()
  const locale = useLocale()

  return (
    <ProductBanner
      Logo={<StreamsLogo height={39} />}
      paragraph={t(
        'You get <strong>Streams</strong> for <strong>Free</strong> with any Lesson subscription.'
      )}
      cta={{
        text: t('Don’t know what Streams is? Find out more!'),
        link: getLocalizedPath(locale, '/streams'),
      }}
    />
  )
}

export default StreamsBanner
