import React from 'react'
import { Box, Flex } from '@chatterbug/aaron'
import { useLessonsPricingPlans } from 'src/data/pricing/plans'
import ProductList from 'src/components/ProductList/ProductList'
import ProductCardForLessonPricingPlan from 'src/components/ProductList/subcomponents/ProductCard/ProductCardForLessonPricingPlan'

import PricingPlanHeading from './subcomponents/PricingPlanHeading/PricingPlanHeading'
import PricingPlanFooter from './subcomponents/PricingPlanFooter/PricingPlanFooter'
import StreamsBanner from './subcomponents/StreamsBanner/StreamsBanner'

const PricingPlans: React.FC = () => {
  const plans = useLessonsPricingPlans()
  return (
    <Flex flexDirection="column" alignItems="center">
      <Box mb="56px">
        <PricingPlanHeading />
      </Box>
      <Box
        display="grid"
        width={{
          mobile: '100%',
          tablet: 'max-content',
        }}
        gridGap="3x"
      >
        <ProductList
          items={plans}
          ItemComponent={ProductCardForLessonPricingPlan}
        />
        <StreamsBanner />
      </Box>
      <Box mt="6x">
        <PricingPlanFooter />
      </Box>
    </Flex>
  )
}

export default PricingPlans
