import React from 'react'
import { Box, Image } from '@chatterbug/aaron'
import { CompareTableValue } from 'src/types'

import { TABLE_CELL_WIDTH } from '../../utils'

import lightCheck from './assets/light_check.svg'
import check from './assets/check.svg'
import cross from './assets/cross.svg'

export type Props = {
  checkmark: CompareTableValue
  hideOnMobile?: boolean
}

const ContentCell: React.FC<Props> = ({ checkmark, hideOnMobile }) => {
  return (
    <Box
      width={TABLE_CELL_WIDTH}
      display={
        hideOnMobile ? { _: 'none', tablet: 'inline-block' } : 'inline-block'
      }
      textAlign="center"
      as="td"
      borderRightWidth={1}
      borderRightStyle="solid"
      borderRightColor="gray60"
      height="70px"
    >
      <Image
        src={
          checkmark === CompareTableValue.check
            ? check
            : checkmark === CompareTableValue.lightCheck
            ? lightCheck
            : cross
        }
        loading="lazy"
        alt={checkmark}
      />
    </Box>
  )
}

export default ContentCell
